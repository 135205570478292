:root {
  --primary-color: #4CAF50;
  --primary-hover: #45a049;
  --secondary-color: #007bff;
  --error-color: red;
}

.exchange-container {
  min-width: 250px;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.exchange-container h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.exchange-container input, 
.exchange-container select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}

.exchange-container button {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
}

.exchange-container button:hover {
  background-color: var(--primary-hover);
}

.exchange-from, 
.exchange-to {
  margin-bottom: 15px;
}

@media screen and (max-width: 425px) {
  .exchange-container {
    transform-origin: top center;
    transform: scale(0.9);
  }
}