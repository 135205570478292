
/* Header.css */
.header {
  background-color: #4CAF50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  margin: 0 auto;
  padding: 0 16px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 32px;
}

.logo {
  height: 40px;
  width: auto;
  cursor: pointer;
}

.desktop-nav {
  display: flex;
  gap: 24px;
}

.desktop-nav a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.desktop-nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.desktop-nav a.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile-menu {
  position: relative;
}

.profile-trigger {
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.8);
  transition: transform 0.2s;
}

.profile-trigger:hover {
  transform: scale(1.05);
}

.profile-picture-2 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
  padding: 0;
  margin: 0;
  display: block; /* Remove any inline spacing */
  border: none; /* Remove any default border */
}

.profile-icon {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 2px solid rgba(255, 255, 255, 0.8); /* Move border to container */
  border-radius: 50%;
  padding: 0; /* Remove padding that might cause gaps */
  overflow: hidden; /* Ensure image doesn't overflow the border */
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 180px;
  overflow: hidden;
}

.profile-link {
  display: block;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.profile-link:hover {
  background-color: #f5f5f5;
}

.logout-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: none;
  color: #dc3545;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  border-top: 1px solid #eee;
}

.logout-button:hover {
  background-color: #fff5f5;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
}

.mobile-nav {
  display: none;
  position: fixed; /* Change this to fixed */
  top: 56px; /* Height of header on mobile */
  left: 0;
  right: 0;
  background-color: white;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999; /* Just below header */
  max-height: calc(100vh - 56px); /* Viewport height minus header height */
  overflow-y: auto; /* Enable scrolling if menu is too long */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  }

  .mobile-nav a {
    color: #333;
    text-decoration: none;
    padding: 12px;
    border-radius: 4px;
  }

  .mobile-nav a:hover {
    background-color: #f5f5f5;
  }

  .mobile-nav a.active {
    background-color: #f0f7f0;
    color: #4CAF50;
  }
  
  .header-content {
    height: 56px;
  }

  .logo {
    height: 32px;
  }

  .profile-picture {
    width: 28px;
    height: 28px;
  }
}