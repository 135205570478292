/* AI.css */
.ai-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.header-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.reset-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background: #f3f4f6;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.reset-button:hover:not(:disabled) {
  background: #e5e7eb;
}

.reset-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-message {
  padding: 1rem;
  background: #fee2e2;
  color: #dc2626;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.status-message {
  padding: 1rem;
  background: #e0f2fe;
  color: #0369a1;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.start-section {
  text-align: center;
  padding: 2rem;
  background: #f9fafb;
  border-radius: 8px;
}

.train-button {
  padding: 0.75rem 1.5rem;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.train-button:hover:not(:disabled) {
  background: #1d4ed8;
}

.train-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.analysis-results {
  margin-top: 2rem;
  display: grid;
  gap: 1.5rem;
}

.card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1.5rem;
}

.card-header {
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.risk-assessment {
  display: grid;
  gap: 1rem;
}

.risk-score {
  font-size: 1.25rem;
  font-weight: 500;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.healthy {
  background: #dcfce7;
  color: #166534;
}

.status-badge.at-risk {
  background: #fef9c3;
  color: #854d0e;
}

.status-badge.high-risk {
  background: #fee2e2;
  color: #991b1b;
}

.recommendations {
  display: grid;
  gap: 0.75rem;
}

.recommendation {
  padding: 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
}

.recommendation.warning {
  background: #fef3c7;
  color: #92400e;
}

.recommendation.alert {
  background: #fee2e2;
  color: #991b1b;
}

.recommendation.suggestion {
  background: #dbeafe;
  color: #1e40af;
}

.metrics-grid {
  display: grid;
  gap: 1rem;
}

.metric-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: #f9fafb;
  border-radius: 6px;
}

.metric-label {
  color: #4b5563;
}

.metric-value {
  font-weight: 500;
}

.metric-value.positive {
  color: #059669;
}

.metric-value.negative {
  color: #dc2626;
}

.metric-value.warning {
  color: #d97706;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@media (max-width: 640px) {
  .ai-container {
    padding: 1rem;
  }
  
  .header-section {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  
  .metrics-grid {
    grid-template-columns: 1fr;
  }
}