/* General Profile Container */
.profile-container {
  text-align: center;
  padding: 30px;
  max-width: 500px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Profile Picture Section */
.profile-picture-section {
  position: relative;
  margin-bottom: 20px;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #4CAF50;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* File Upload Button */
.upload-label {
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: bold;
  color: #4CAF50;
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s ease;
}

.upload-label:hover {
  color: #357a38;
}

/* Profile Info Section */
.profile-info {
  margin-top: 20px;
}

.name-container,
.date-container,
.gender-container,
.currency-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}


/* Input and Select Fields */
.name-container input[type="text"],
.name-container input[type="email"],
.date-container input[type="date"],
.gender-container select,
.currency-container select {
  font-size: 1em;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  text-align: center;
}

/* Buttons */
.delete-profile-btn {
  margin-top: 20px;
  padding: 5px 5px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-profile-btn:hover {
  background-color: #c9302c;
}
 
.change-password-btn {
  margin-top: 20px;
  padding: 5px 5px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: #4f64d9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-password-btn:hover {
  background-color: #2c51c9;
}

.currency-profile-btn {
  margin-top: 20px;
  padding: 5px 5px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: #4fd9c2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.currency-profile-btn:hover {
  background-color: #3fb39f;
}

.edit-profile-controls {
  position: relative; /* Position relative to the profile-container */
  top: 10px; /* Adjust vertical placement */
  right: 10px; /* Adjust horizontal placement */
  display: flex;
  gap: 10px; /* Space between icons */
  z-index: 1; /* Ensure it's above other content */
}

.icon-edit,
.icon-confirm,
.icon-cancel {
  font-size: 1.2em; /* Icon size */
  cursor: pointer;
  color: white; /* Icon color */
  border: 2px solid #333;
  background-color: rgba(0, 0, 0, 0.3); /* Add a semi-transparent background */
  padding: 8px;
}

.icon-edit:hover {
  color: #007bff;
  background-color: rgba(0, 123, 255, 0.8);
}

.icon-confirm:hover {
  color: #28a745;
  background-color: rgba(40, 167, 69, 0.8);
}

.icon-cancel:hover {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.8);
}

.icon-cancel-picture {
  font-size: 0.8em; /* Icon size */
  cursor: pointer;
  color: #dc3545;
  padding: 8px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-delete-btn,
.clear-btn,
.confirm-btn,
.cancel-btn {
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.confirm-delete-btn {
  background-color: #d9534f;
  color: white;
}

.confirm-btn {
  background-color: blue;
  color: white;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

  
  .delete-password-input {
    margin-top: 10px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  @media screen and (max-width: 425px) {
    .profile-container {
      transform-origin: top center;
      transform: scale(0.85);
      width: calc(80% / 0.85);
      text-align: center;
    }
  
    .name-container,
    .date-container,
    .gender-container,
    .currency-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      width: 100%;
    }
  
    .name-container input[type="text"],
    .name-container input[type="email"],
    .date-container input[type="date"],
    .gender-container select,
    .currency-container select {
      font-size: 1em;
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
    }
  
    .date-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; /* Stack content vertically */
      margin: 10px 0; /* Add some spacing */
    }
  
    .date-container input[type="date"] {
      font-size: 1em;
      padding: 8px;
      text-align: center; /* Center text inside the input */
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%; /* Make it full width to ensure alignment */
      max-width: 300px; /* Limit the width to avoid stretching too far */
    }
  
    .modal-overlay {
      transform-origin: top right;
      transform: scale(1.15);
      width: calc(100% * 1.15);
    }
  
    .modal-content {
      transform-origin: top center;
      transform: scale(0.85);
      width: calc(80% / 0.85);
      font-size: 0.9em;
    }
  
    .profile-container button {
      padding: 8px 12px;
      font-size: 0.7em;
    }
  }
  