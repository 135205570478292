.search-container {
    flex: 1;
    max-width: 400px;
  }
  
  .search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    position: absolute;
    left: 10px;
    color: #666;
  }
  
  .search-input {
    width: 100%;
    margin-right: 20px;
    padding: 8px 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .search-input:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
  
  .search-container .clear-search {
    position: absolute;
    right: 30px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .clear-search:hover {
    color: #333;
  }