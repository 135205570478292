footer {
  position: fixed;
  bottom: 0;
  width: 100%; /* Full width for the footer */
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: white;
  border-radius: 8px;
  z-index: 500;
}
  
  footer p {
    margin: 0;
    font-size: 0.9em;
  }