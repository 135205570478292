  .listbox-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .listbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .listbox-header h3 {
    margin: 0;
    color: var(--primary-color);
  }

  .listbox-content {
    display: flex;
    flex-direction: column; /* Ensure items stack vertically */
    gap: 10px; /* Add spacing between items */
    height: calc(100vh - 350px);
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .listbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .listbox-item:hover {
    background-color: #f3f4f6;
  }

  .listbox-item.selected {
    background-color: #e8f0fe;
  }

  .listbox-item-actions {
    display: flex;
    gap: 6px;
  }
  
  .icon-group-select {
    display: flex;
    flex: none;
    gap: 4px;
  }
  
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background: none;
    border: 1px solid gray;
    border-radius: 6px;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .icon-button.add {
    color: #2563eb;
  }

  .icon-button.edit {
    color: #4CAF50;
  }
  
  .icon-button.delete {
    color: #dc2626;
  }
  
  .icon-button.add:hover {
    background-color: #babcbe;
  }

  .icon-button.edit:hover {
    background-color: #babcbe;
  }
  
  .icon-button.delete:hover {
    background-color: #babcbe;
  }

  .icon-select {
    width: 15px;
    height: 15px;
  }