.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-delete-btn,
.clear-btn,
.confirm-btn,
.cancel-btn {
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.confirm-btn {
  background-color: blue;
  color: white;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

.currency-selector {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
  }
  
  /* Checkbox list container */
  .currency-selector .checkbox-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  /* Scrollbar styles */
  .currency-selector .checkbox-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .currency-selector .checkbox-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .currency-selector .checkbox-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .currency-selector .checkbox-list::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Checkbox items */
  .currency-selector .checkbox-item {
    display: flex;
    align-items: center;
    padding: 8px;
    transition: background-color 0.2s;
    border-radius: 4px;
  }
  
  .currency-selector .checkbox-item:hover {
    background-color: #f5f5f5;
  }
  
  .currency-selector .checkbox-item input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
  
  .currency-selector .checkbox-item label {
    cursor: pointer;
    font-size: 14px;
    color: #333;
    flex-grow: 1;
  }
  
  /* Default currency selection */
  .currency-selector .default-currency-select {
    margin-top: 15px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  
  .currency-selector .default-currency-select label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
  }
  
  .currency-selector .default-currency-select select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
    background-color: white;
    cursor: pointer;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .currency-selector .default-currency-select select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .currency-selector .default-currency-select select.input-error {
    border-color: #dc3545;
  }
  
  /* Currency list display in profile view */
  .currency-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
  
  .currency-item {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  
  .currency-item.default {
    background-color: #e7f3ff;
    border-color: #b3d7ff;
  }
  
  .default-badge {
    background-color: #007bff;
    color: white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .modal-overlay {
      transform-origin: top right;
      transform: scale(1.15);
      width: calc(100% * 1.15);
    }
  
    .modal-content {
      transform-origin: top center;
      transform: scale(0.85);
      width: calc(80% / 0.85);
      font-size: 0.9em;
    }
    
    .currency-selector {
      padding: 10px;
    }
  
    .currency-selector .checkbox-list {
      max-height: 150px;
    }
  
    .currency-item {
      width: 100%;
    }
}