.floating-alert {
    position: fixed;
    top: 100px;
    right: 10px;
    max-width: 300px;
    background: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slide-in 0.3s ease-out;
  }
  
  .floating-alert p {
    margin: 0;
    font-size: 0.9em;
    color: #333;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: #888;
    font-size: 1.2em;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.2s ease;
  }
  
  .close-btn:hover {
    color: #555;
  }
  
  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  