:root {
  --primary-color: #4CAF50;
  --primary-hover: #45a049;
  --secondary-color: #007bff;
  --error-color: red;
}

.login-container {
  min-width: 250px;
  width: 400px;
  max-width: 425px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4CAF50;
}

.login-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-container input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-container select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-container button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: var(--primary-hover);
}

.login-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.google-btn {
  background-color: white;
  color: #757575;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.google-btn img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.google-btn:hover {
  background-color: #f7f7f7;
}

.secondary-btn {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.secondary-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.forgot-password {
  margin-top: 15px;
  color: var(--secondary-color);
  font-size: 0.9em;
  cursor: pointer;
}

.forgot-password a {
  color: var(--secondary-color);
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.error-message {
  color: var(--error-color);
  font-size: 0.9em;
  margin-bottom: 10px;
}

.input-error {
  border-color: var(--error-color) !important;
}

@media screen and (max-width: 425px) {
  .login-container {
    transform-origin: top center;
    transform: scale(0.85);
    width: calc(80% / 0.85);
  }

  /* Adjust action buttons */
  .login-container button {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}