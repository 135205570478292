:root {
  --primary-color: #4CAF50;
  --primary-hover: #45a049;
  --secondary-color: #007bff;
  --error-color: red;
}

.category-container {
  min-width: 250px;
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.category-container h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.category-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.select-container {
  position: relative;
  display: flex;
  align-items: baseline;
}

.custom-select-group{
  width: 100%;;
  align-items: flex-end;
  display: inline-flex;
}

.select-container select,
.select-container input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  height: 100%;
}

.icon-group {
  display: flex;
  gap: 6px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.modal-content select,
.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-delete-btn,
.clear-btn,
.confirm-btn,
.cancel-btn {
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.confirm-delete-btn {
  background-color: #d9534f;
  color: white;
}

.confirm-btn {
  background-color: blue;
  color: white;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

@media screen and (max-width: 425px) {
  .category-container {
    transform-origin: top center;
    transform: scale(0.85);
    width: calc(80% / 0.85);
  }

  .modal-overlay {
    transform-origin: top right;
    transform: scale(1.15);
    width: calc(100% * 1.15);
  }

  .modal-content {
    transform-origin: top center;
    transform: scale(0.85);
    width: calc(80% / 0.85);
    font-size: 0.9em;
  }
}