/* CSS Styles */
.sidebar-container {
    position: relative;
    height: 100%;
    background: white;
    border-right: 1px solid #e5e7eb;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
    transition: width 0.3s ease;
    z-index: 50;
  }
  
  .sidebar-expanded {
    width: 256px;
    min-width: 256px;
  }
  
  .sidebar-collapsed {
    width: 0;
  }
  
  .sidebar-toggle {
    position: absolute;
    right: -24px; /* slightly wider to fit bookmark */
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 80px; /* taller like a bookmark */
    background: #4CAF50;
    border: none;
    border-radius: 0 4px 4px 0;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: background-color 0.2s;
  }
  
  .sidebar-toggle:hover {
    background: #45a049;
  }
  
  .sidebar-toggle:active {
    cursor: grabbing;
  }
  
  /* Make the chevron icon white */
  .sidebar-toggle svg {
    color: white;
  }
  
  .sidebar-content {
    padding: 16px;
    height: 100%;
    overflow-y: auto;
    transition: opacity 0.3s ease;
  }
  
  .content-visible {
    opacity: 1;
    visibility: visible;
  }
  
  .content-hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .sidebar-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 16px;
  }
  
  .filter-section {
    margin-bottom: 20px;
  }
  
  .filter-section h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .checkbox-list {
    display: flex;
    flex-direction: column; /* Ensure items stack vertically */
    gap: 10px; /* Add spacing between items */
    max-height: 128px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .checkbox-item input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .checkbox-item label {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  
  .input-field {
    margin-bottom: 8px;
  }
  
  .input-field label {
    display: block;
    font-size: 0.75rem;
    color: #6b7280;
    margin-bottom: 4px;
  }
  
  .input-field input {
    width: 80%;
    padding: 4px 8px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 0.875rem;
  }

 .input-field select {
    width: 100%;
    padding: 8px;
    font-size: 0.875rem;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    background-color: #fff;
    color: #1e293b;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
 }
  
  @media (max-width: 768px) {
    .sidebar-container {
      position: fixed;
      height: calc(100vh - var(--header-height, 64px));
      top: var(--header-height, 64px);
      left: 0;
      transform: translateX(calc(-100% + 48px));
      transition: transform 0.3s ease, width 0.3s ease;
    }
    
    .sidebar-expanded {
      width: 85%;
      max-width: 256px;
      transform: translateX(0);
    }
  
    .sidebar-collapsed {
      width: 0;
      transform: translateX(calc(-100%));
    }
  
    /* Add overlay when sidebar is expanded */
    .sidebar-overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 40;
    }
  
    .sidebar-expanded + .sidebar-overlay {
      display: block;
    }
  }