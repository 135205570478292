
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure it overlays everything */
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%;
    max-width: 450px;
  }
  
  .modal button {
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
  } 

  .close-btn {
    display: block;
    padding: 8px 16px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #63cc4e;
    color: white;
    margin: auto;
  }