.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 20px;
  background-color: #f0f0f0;
}

.page {
  overflow-y: auto; /* Enable scrolling if the content overflows */
  width: 100%; /* Keep the width as 100% */
  max-height: calc(100vh - 105px); /* Deduct header and footer height (adjust as necessary) */
  height: calc(100vh - 105px); /* Deduct header and footer height (adjust as necessary) */
  box-sizing: border-box; /* Include padding in width/height calculations */
}