.data-page {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
}

.data-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: 24px;
  gap: 24px;
  max-height: 100%;
  overflow: auto;
}

/* Sidebar */
.sidebar {
  min-width: 250px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
  position: relative;
  overflow-y: auto;
  z-index: 10;
  text-align: left;
}

.sidebar h3 {
  margin-top: 0;
  font-size: 1.25em;
  color: #333;
}

.sidebar select,
.sidebar input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.sidebar button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.sidebar button:hover {
  background-color: #45a049;
}

/* Data Table Container */
.data-table-container {
  width: 100%;
  height: 100%;
}

/* Flexbox container for alignment */
.data-actions-container {
  display: flex;
  justify-content: space-between; /* Align left and right sections */
  align-items: center; /* Vertically align buttons */
  margin: 10px 0;
}

/* Left-side buttons */
.data-actions-left,
.data-actions-right,
.data-actions-middle {
  display: flex;
  gap: 10px; /* Add spacing between buttons */
}

/* Button styling */
.data-actions-container button {
  padding: 10px 15px;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex; /* Ensure icon and label are aligned */
  align-items: center; /* Vertically align text and icons */
  gap: 5px; /* Add spacing between label and icon */
  cursor: pointer;
}

.data-actions-container button:hover {
  background-color: #45a049;
}

/* Input (hidden for file upload) */
.data-actions-container input[type="file"] {
  display: none;
}

/* Data Table Scroll */
.data-table-scroll {
  max-height: calc(100vh - 300px);
  height: calc(100vh - 300px);;
  min-width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
}

.data-table-scroll table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0; /* Default sticky position for the first row */
  background-color: #ddd;
  padding: 10px;
  text-align: center;
  z-index: 2; /* Ensure it stays above the table body */
  white-space: nowrap;
}

thead tr:nth-child(2) th {
  top: 40px; /* Adjust this value to match the height of the first row */
  background-color: #f8f8f8; /* Different background for clarity */
  z-index: 1; /* Stays below the first row */
}

thead th label {
  cursor: pointer;
  color: black;
}

tbody td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s; /* Smooth transition for hover */
}

/* Remove background from individual cells */
tbody td:nth-child(even),
tbody td:nth-child(odd) {
  background-color: transparent;
}

/* Add zebra striping to rows instead */
tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

/* Hover effect on rows */
tbody tr:hover td {
  background-color: #94dfc0fd !important;
}

/* Editable and Regular Rows */
.editable-row td {
  background-color: #5acf99fd !important;
  cursor: pointer;
}

.regular-row {
  cursor: pointer;
}

/* Optional: Add different hover color for editable rows */
.editable-row:hover td {
  background-color: #5ad9affd !important;
}

/* Delete Button */
.delete-btn {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  min-width: 250px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 5px;
}

.items-per-page label {
  font-size: 14px;
}

.items-per-page input {
  width: 60px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-delete-btn,
.clear-btn,
.confirm-btn,
.cancel-btn {
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.confirm-delete-btn {
  background-color: #d9534f;
  color: white;
}

.confirm-btn {
  background-color: blue;
  color: white;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

.filter-tabs{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.category-list {
  display: flex;
  flex-direction: column; /* Ensure items stack vertically */
  gap: 10px; /* Add spacing between items */
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.category-item,
.category-item-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0; /* Adds vertical spacing */
}

.category-item-list label {
  text-align: left; /* Aligns label text to the left */
  margin-left: 20px;
}

.category-item label {
  text-align: left; /* Aligns label text to the left */
  margin-left: 20px;
  flex: 1 1 30%; /* Takes the remaining space for inputs */
}

.category-item input[type="date"],
.category-item input[type="number"] ,
.category-item select {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures consistent box-sizing */
  width:100%;
}

.category-item input[type="checkbox"] {
  flex: 0 0 auto; /* Prevents checkbox from stretching */
  margin: 0; /* Removes default margin */
}

.category-item:hover {
  background-color: #f2f2f2;
}

.filter-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 5px;
  border: none;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 4px;
}

.clear-filter {
  margin-left: 5px;
  cursor: pointer;
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.clear-btn {
  background-color: #ffcccc;
  color: #333;
}

.filter-tabs button {
  padding: 10px;
  border: none;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 4px;
}

.filter-tabs button.active-tab {
  background: #007bff;
  color: white;
}

.filter-icon,
.sort-icon {
  font-size: 0.8em; /* Match icon size to text */
  margin-left: 5px; /* Space between input and icons */
  display: inline-flex;
  cursor: pointer;
}

.add-icon {
  color: #007bff;
}

.edit-icon {
  color: #28a745;
}

.delete-icon {
  color: #dc3545;
}

.confirm-icon {
  color: #28a745;
}

.cancel-icon {
  color: #dc3545;
}

.icon:hover {
  opacity: 0.8;
}

.stacked-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  column-gap: 15px; /* Space between items */
}

.stacked-inputs label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold; /* Optional for emphasis */
  text-align: center;
}

.stacked-inputs select,
.stacked-inputs input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box; /* Ensure padding doesn’t affect width */
}

/* Add to your CSS */
.toggle-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5855f3;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #4CAF50;
}

input:checked + .toggle-slider:before {
  transform: translateX(30px);
}

.toggle-label {
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 425px) {
  .modal-overlay {
    transform-origin: top right;
    transform: scale(1.15);
    width: calc(100% * 1.15);
  }

  .modal-content {
    transform-origin: top center;
    transform: scale(0.85);
    width: calc(80% / 0.85);
    font-size: 0.9em;
  }

  .data-page {
    flex-direction: column;
    padding: 16px;
  }

  .data-main {
    padding: 16px;
    gap: 16px;
  }

  /* Adjust padding and margins */
  tbody td, thead th {
    padding: 8px 6px; /* Slightly smaller padding */
  }

  thead tr:nth-child(2) th {
    top: 31px; /* Adjust this value to match the height of the first row */
  }

  /* Make text smaller but still readable */
  .data-table-scroll table {
    font-size: 14px;
  }

  /* Adjust action buttons */
  .data-actions-container button {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}

