.register-container {
    max-width: 425px;
    min-width: 250px;
    width: 400px;
    margin: auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .register-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .register-container label {
    font-size: 0.9em;
    color: #333;
    margin-bottom: 5px;
    display: inline-block; /* Keep labels inline for alignment */
  }
  
  .register-container input,
  .register-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .register-container input:focus,
  .register-container select:focus {
    border-color: #4CAF50;
    box-shadow: 0px 0px 4px rgba(76, 175, 80, 0.5);
    outline: none;
  }
  
  .register-container input:hover,
  .register-container select:hover {
    border-color: #4CAF50;
  }
  
  .register-container button {
    width: 100%;
    padding: 12px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-container button:hover {
    background-color: #45a049;
  }
  
  .recaptcha-container {
    margin-bottom: 15px;
    text-align: center;
    justify-content: center;
  }
  .error-message {
    color: var(--error-color);
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  
  .input-error {
    border-color: var(--error-color) !important;
  }
  
  .loader {
    margin-top: 10px;
    color: #4CAF50;
    font-size: 1em;
    font-weight: bold;
  }
  
  @media screen and (max-width: 425px) {
    .register-container {
      transform-origin: top center;
      transform: scale(0.85);
      width: calc(80% / 0.85);
    }

    .recaptcha-container {
      transform-origin: top left;
      transform: scale(0.85);
      width: calc(80% / 0.85);
    }
  
    /* Adjust action buttons */
    .register-container button {
      padding: 8px 12px;
      font-size: 0.9em;
    }
  }