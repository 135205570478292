.dashboard-page {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
}

.dashboard-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: 24px;
  gap: 24px;
  max-height: 100%;
  overflow: auto;
}

/* Styles for the dashboard-control */
.dashboard-control {
  width: 100%; /* Full width */
  background: #f8fafc; /* Light background */
  border-bottom: 1px solid #e2e8f0; /* Subtle border */
  z-index: 10; /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between items */
  padding: 10px 24px; /* Padding around content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional subtle shadow */
  flex-wrap: nowrap; /* Prevent wrapping by default */
  height: auto; /* Adjust height dynamically */
  text-align: center;
}

/* Styles for the dashboard-control */
.dashboard-control-left {
  display: flex;
  align-items: center;
  justify-content:flex-start; /* Space between items */
  flex-wrap: wrap; /* Prevent wrapping by default */
  height: auto; /* Adjust height dynamically */
  text-align: center;
}

.chart-control {
  width: 100%; /* Full width */
  background: #f8fafc; /* Light background */
  border-bottom: 1px solid #e2e8f0; /* Subtle border */
  z-index: 10; /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between items */
  padding: 10px 24px; /* Padding around content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional subtle shadow */
  flex-wrap: nowrap; /* Prevent wrapping by default */
  height: auto; /* Adjust height dynamically */
  text-align: center;
}

/* Chart Container */
.chart-container {
  flex: 1 1 calc(50% - 12px);
  min-width: 400px;
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-x: auto;
}

.chart-scroll-wrapper {
  width: 100%;
  overflow-x: auto;
}

/* Individual Chart Styling */
.chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.chart button {
  margin-left: 0;
  display: block;
  padding: 12px;
  font-size: 0.7em;
  font-weight: bold;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chart canvas {
  width: 100% !important;
  height: auto !important; /* Allow the chart to adjust height dynamically */
  max-height: 400px; /* Optional: Restrict maximum height */
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 450px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.cancel-btn {
  padding: 8px 16px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
}

/* Responsive Adjustments */
@media screen and (max-width: 425px) {
  .modal-overlay {
    transform-origin: top right;
    transform: scale(1.15);
    width: calc(100% * 1.15);
  }

  .modal-content {
    transform-origin: top center;
    transform: scale(0.85);
    width: calc(80% / 0.85);
    font-size: 0.9em;
  }

  .dashboard-page {
    flex-direction: column;
    padding: 16px;
  }

  .dashboard-main {
    width: 100%;
    padding: 16px;
    gap: 16px;
  }
  
  .chart-control {
    flex-wrap: nowrap; /* Allow items to wrap */
    padding: 10px; /* Reduce padding for smaller screens */
    height: auto; /* Let height adjust based on wrapped items */
    text-align: center;
    font-size: 0.7em;
  }

  .chart-container {
    flex: 1 1 100%;
    min-width: 0;
    width: 100%;
  }

  .chart-scroll-wrapper {
    min-width: 400px;
  }

  .chart .toggle-switch-container span {
    font-size: 0.6em;
  }

  .chart {
    margin: 0 auto; /* Center the chart on the screen */
  }
  
  .chart h3 {
    font-size: 0.7rem;
    margin-bottom: 16px;
  }

  .chart button
  .chart input {
    padding: 8px 12px;
    font-size: 0.6em;
    top: 0;
  }

  .toggle-label {
    font-size: 0.9em;
  }
  
  .dashboard-control button {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}