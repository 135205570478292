.verify-account-container {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

.verify-account-container h1 {
  color: #4CAF50;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.verify-account-container p {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 20px;
}

.btn-primary {
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #45a049;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.verify-account-container button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verify-account-container button:hover {
  background-color: var(--primary-hover);
}

.verify-account-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}