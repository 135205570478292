.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: #b8e0c9; /* Light background matching logo's tone */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 10px; /* Smooth corners */
  padding: 20px;
}

.spinner {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 20px 0;
}

.double-bounce1, 
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4CAF50; /* Green matching logo */
  opacity: 0.8;
  position: absolute;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

.spinner-message {
  font-size: 1.2em;
  font-weight: bold;
  color: #004d40; /* Darker green for text readability */
  text-align: center;
  margin-top: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  .spinner {
    width: 60px;
    height: 60px;
  }

  .spinner-message {
    font-size: 1em;
  }
}
