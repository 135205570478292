.notification-bell-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.notification-icon {
  position: relative;
  cursor: pointer;
  color: white;
  transition: transform 0.2s ease;
}

.notification-icon:hover {
  transform: scale(1.1);
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 6px;
  font-weight: bold;
  min-width: 18px;
  text-align: center;
}

.notification-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  width: 320px;
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
  cursor: default;
  transition: background-color 0.2s;
}

.notification-item.unread {
  background-color: #f0f8ff;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.notification-message {
  font-size: 13px;
  color: #666;
  line-height: 1.4;
}

.notification-time {
  font-size: 11px;
  color: #999;
  margin-top: 4px;
  text-align: right;
}

.loading-spinner {
  padding: 20px;
  text-align: center;
  color: #666;
}

.no-notifications {
  padding: 20px;
  text-align: center;
  color: #666;
  font-style: italic;
}

@media (max-width: 480px) {
  .notification-dropdown {
    width: 280px;
    max-height: 350px;
    right: -70px;
  }

  .notification-title {
    font-size: 13px;
  }

  .notification-message {
    font-size: 12px;
  }
}